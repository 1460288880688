import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './loading.module.scss';

export default function Loading() {
  return (
    <div className={styles.container}>
      <CircularProgress />
    </div>
  );
}
