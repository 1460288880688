// eslint-disable-next-line
export default {
  id: 'chinezinhas',
  type: 'sweet',
  title: 'Chinezinhas',
  ingredients: [
    '1 lata de leite condensado',
    '? ameixas',
    '? anelina azul, vermelha e marrom',
    '? papel dourado e pincel número 5',
    '? doce de leite',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'A cabeça das chinesas é de doce de leite bem duro (1 hora e 1/2 em banho maria)',
        'Bote-se na caçarola e vai ao fogo para dar o ponto tendo cuidado para não queimar',
        'Em seguida, faz-se a cara das chinesas na forma de gêsso',
        'Bota-se uma ameixa sem caroço partida ao meio sem separar um lado para fazer o cabelo',
        'Pinta-se os olhos de azul, a boca de vermelho, a sobrancelha e o cabelo de marrom e as bochecas de vermelho',
        'Bota-se os grampos (pedaços de palito coberto com papel de chocolate vermelho e azul)',
      ],
    },
  ],
};
