import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import styles from 'Layout/components/search.module.scss';
import { useHistory } from 'react-router-dom';

export default function Search() {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    history.push(`/procurar?${searchText}`);
  };

  return (
    <div className={styles.search}>
      <div className={styles.icon}>
        <SearchIcon />
      </div>
      <form onSubmit={handleSubmit}>
        <InputBase
          placeholder='Procurar...'
          className={styles.input}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleChange}
        />
      </form>
    </div>
  );
}
