// eslint-disable-next-line
export default {
  id: 'pudim_de_classe_media',
  type: 'sweet',
  title: 'Pudim de classe média',
  ingredients: [
    '2 latas de leite moça',
    '1 lata de creme de leite',
    '1 lata de leite (a mesma do leite condensado)',
    '3 ovos',
    '1 colher de chá de maisena (ou 2 ovos a mais)',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Pré aqueça o forno',
        'Bata todos os ingredientes no liquidificador ',
        'Coloque a mistura em uma travessa em banho maria (2 dedos de água) em fogo baixo',
        'Leva em média de 1 a 2 horas para ficar pronto',
        'Ele estará pronto quando ele ficar dourado e quando você enfiar o palito ou garfo e a consistência tá cremosa e não líquida',
        'Retire do forno e coloque na geladeira de 3 a 4 horas antes de servir',
      ],
    },
  ],
};
