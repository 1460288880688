// eslint-disable-next-line
export default {
  id: 'olho_de_sogra',
  type: 'sweet',
  title: 'Olho de sogra',
  ingredients: [
    '3 colheres de queijo do reino',
    '50 gramas de amendoas',
    '1 coco ralado bem fininho',
    '4 gemas',
    '1 copo de leite de vaca',
    '1 prato raso de açucar',
    '1 colher de chá de baunilha',
    '1 colher de sopa de farinha de trigo',
    '? ameixas',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Misturam-se os ingredientes e leva-se ao fogo para cozinhar',
        'Quando estiver soltando das bordas da panela está pronto',
        'Abre-se as ameixas e tira-se o caroço',
        'Coloca-se o recheio e polvilha com açucar cristal',
      ],
    },
  ],
};
