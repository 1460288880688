// eslint-disable-next-line
export default {
  id: 'macarrao_de_feta',
  type: 'savoury',
  title: 'Macarrão de feta',
  photos: 6,
  ingredients: [
    'Tomate cereja',
    'Azeite',
    '1 tablete de queijo feta',
    'Ervas finas',
    'Pimenta do reino',
    '6 alhos',
    'Macarrão',
    'Sal ou shoyo',
    '1 peito de frango',
    '1 limão',
    'Paprika',
    'Mostarda',
  ],
  parts: [
    {
      title: 'Molho',
      methodOfPreparation: [
        'Coloque o forno para aquecer a 180 graus',
        'Coloque numa travessa tomate cereja',
        'Coloque os alhos com casca',
        'Coloque sal, azeite, pimenta do reino e ervas finas',
        'Misture tudo com a mão',
        'Coloque o queijo feta no meio da travessa',
        'Coloque azeite, pimenta do reino e ervas finas em cima do queijo',
        'Coloque no forno por 30 minutos',
        'Tire as cascas do alho',
        'Amasse os alhos',
        'Amasse os queijos',
        'Misture tudo até virar um molho',
      ],
    },
    {
      title: 'Macarrão',
      methodOfPreparation: [
        'Coloque água para ferver',
        'Quando começar a ferver, coloque sal',
        'Coloque o macarrão',
        'Use um garfo para mexer o macarrão para ele não grudar',
        'Quando o macarrão começar a ficar mole prove para saber se o ponto dele está bom para você',
      ],
    },
    {
      title: 'Frango (Opcional)',
      methodOfPreparation: [
        'Coloque limão, sal ou shoyo, pimenta do reino, um pouco de mostarda e paprika dos dois lados do frango',
        'Deixe um tempo para pegar o gosto',
        'Asse no forno aquecido por alguns minutos, asse na frigideira ou asse na air fryer',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloque o macarrão, manjericão e o frango no molho',
        'Misture tudo bem para que o molho pegue em todo macarrão e frango',
      ],
    },
  ],
};
