// eslint-disable-next-line
export default {
  id: 'bobo_de_camarao',
  type: 'savoury',
  title: 'Bobó de camarão',
  ingredients: [
    '500g camarões',
    '1 limão',
    'sal',
    'pimenta do reino',
    'azeite',
    '1 e 1/2 cebola',
    '4 cabeças de alho',
    '1 tomate',
    '1 pimentão',
    'coentro',
    'cebolinha',
    'cabeça do camarão (Opcional)',
    '1 litro de leite de coco',
    '1kg macaxeira',
  ],
  parts: [
    {
      title: 'Camarões',
      methodOfPreparation: [
        'Coloque os camarões em uma tigela com limão, sal, pimenta do reino, azeite, a cebola ralada e o 2 alhos ralados',
      ],
    },
    {
      title: 'Caldinho de tempero',
      methodOfPreparation: [
        'Refoga-se o alho e a cebola no azeite temperando com sal e pimenta a gosto',
        'Caso tenha a cabeça do camarão faz-se um refogado com essas cabeças e todos os temperos no leite de coco para formar um caldinho de camarão',
      ],
    },
    {
      title: 'Macaxeira',
      methodOfPreparation: ['Cozinhe a macaxeira', 'Passe no liquidificador'],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Misture o camarão, o caldo com tempeiro e a macaxeira na panela',
        'Leve ao forno',
        'Ajuste o sabor com temperos caso necessário',
        'As vezes coloco um pedaço de um queijo bom para dar um toque ao bobó',
      ],
    },
  ],
};
