// eslint-disable-next-line
export default {
  id: 'creme_de_espinafre',
  type: 'savoury',
  title: 'Creme de espinafre',
  ingredients: [
    '260g de espinafre',
    '125g de creme de leite',
    '20g de queijo parmesão ralado',
    'Noz moscada (fresca)',
    '1/2 colher de chá de sal marinho',
  ],
  parts: [
    {
      title: 'Creme de espinafre',
      methodOfPreparation: [
        'Coloque o espinafre na panela de água fervente (sem sal) por 1 minuto. Faça isso em duas porções',
        'Escorra toda a água. Esprema todo os espinafres ate sair toda a agua de dentro. Esprema quanto puder, quanto menos agua melhor pra consistencia',
        'Depois de retirar tofda agua, voce tera 100-120g de espinafre amassado/espremmido',
        'Em etapas, pique o espinafre em pedacinhos bem finos',
        'Numa panela em fogo baixo para médio, coloque o creme de leite, o queijo parmesão, sal e noz moscada fresca. Cuidado, o gosto da nnoz moscada eh bem forte, pode ir colocando aos poucos e ir provando',
        'Adicione o espinafre que foi cortado em pedacos finos e mexa ate ficar uniforme e aquecido. Isso leva aproximadamente 2 minutos',
        'Prove, adicione mais noz moscada se desejar e sirva logo em seguida',
      ],
    },
  ],
};
