// eslint-disable-next-line
export default {
  id: 'lasanha_de_berinjela',
  type: 'savoury',
  title: 'Lasanha de berinjela',
  ingredients: [
    '2 Berinjelas',
    '1 Cebola',
    '2 dentes de alho',
    '3 tomates',
    'Azeite',
    '500g molho de tomate',
    '500 ml de bechamel',
    '1 colher de sopa de extrato de tomate',
    '2 ou 3 dedos de água',
    '1 colher de sopa de salsinha',
    '3 folhas de manjericão',
    '1 caldo de kinor vegetal',
    'Sal',
    'Pimenta do reino',
    'Queijo parmesão ralado',
  ],
  parts: [
    {
      title: 'Preparando',
      methodOfPreparation: [
        'Refogue a cebola e o alho em fogo baixo',
        'Quando as cebolas estiverem molinhas, acrescente o tomate e o kinorr. Deixe refogar',
        'Acrescente os cubinhos de beringela e deixe ela refogar também até ficar mole',
        'Acrescente o molho de tomate, a água, o extrato de tomate, a salsinha e o manjericão',
        'Acrescente sal e pimenta do reino a gosto',
        'Deixe cozinhando em fogo baixo por uns 25 min',
      ],
    },
    {
      title: 'Montando',
      methodOfPreparation: [
        'Em uma tigela, coloque nessa sequência de baixo para cima:',
        'Uma camada de berinjela,',
        'Uma de massa',
        'Uma de bechamel',
        'Berinjela... etc',
        'Sua última camada tem que ser de bechamel',
        'A massa que eu usei eu deixei no forno por 10 min',
        'Tirei e coloquei o queijo por cima e deixei mais 5min até dourar.',
      ],
    },
  ],
};
