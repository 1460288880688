// eslint-disable-next-line
export default {
  id: 'carne_ao_molho_ingles',
  type: 'basic',
  title: 'Carne ao molho inglês',
  ingredients: ['Sal grosso', '1 pedaço de carne', 'Molho inglês', 'mel'],
  parts: [
    {
      title: 'Carne',
      methodOfPreparation: [
        'Coloque sal dos dois lados da carne',
        'Coloque em uma frigideira molho inglês e uma ou duas colheres de chá de mel',
        'Misture bem o molho inglês com o mel até ficar uniforme a mistura',
        'Coloque a carne na frigideira, colocando o molho dos dois lados',
        'Quando a frigideira estiver bem quente coloque a carne e logo em seguida vire a carne para evitar que saia líguido dela',
        'Coloque em forno médio para baixo se não queimará por fora e ficará cru por dentro',
        'Coloque o resto do molho da frigideira na carne',
        'Quanto mais grossa, mais difícil é de acertar o ponto de forma que não fique crua dentro',
        'Você pode cortar em cima da carne para checar se ta crua',
        'Fazendo vários retalhos dos dois lados faz com que a carne asse melhor por dentro',
      ],
    },
  ],
};
