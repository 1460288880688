// eslint-disable-next-line
export default {
  id: 'camarao_molho_coco',
  type: 'savoury',
  title: 'Camarão ao molho de coco',
  ingredients: [
    '1kg camarão com casca (ou 500g sem casca)',
    '1 limão',
    '1/2 lata de leite de coco',
    '2 colheres de passata',
    '1 fatia de cebola',
    '3 dentes de alho',
    '? sal',
    '? pimenta do reino',
    '? cebolinha',
    '? ervas finas',
  ],
  parts: [
    {
      title: 'Cozinhar camarão',
      methodOfPreparation: [
        'Coloque água numa panela com sal e deixe ferver',
        '(Opcional) Exprema limão',
        'Quando começar a borbulhar, coloque os camarões com casca e cabeça',
        'Deixe entre 3 a 5 minutos',
        'Retire os camarões da água',
        'Tire as cabeças e as cascas',
      ],
    },
    {
      title: 'Camarão ao molho de coco',
      methodOfPreparation: [
        'Frite a cebola no azeite',
        'Quando a cebola começar a dourar, coloque a cebolinha e o alho',
        'Coloque o leite de coco',
        'Coloque as colheres de passata',
        'Coloque limão',
        'Coloque sal, pimenta do reino e ervas finas',
        'Misture bem, você pode ate colocar num mini liquidificador de quiser',
        'Coloque os camarões e deixe em fogo baixo por uns minutos',
        'Os camarões estão perfeitamente feitos se estão em formado de C',
      ],
    },
  ],
};
