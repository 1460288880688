import User from '_Api/User';

export const users = [
  new User({
    key: 'anamaria',
    name: 'Anamaria',
  }),
  new User({
    key: 'gabi',
    name: 'Ga',
  }),
  new User({
    key: 'lucia',
    name: 'Lúcia',
  }),
  new User({
    key: 'marcella',
    name: 'Marcella',
  }),
  new User({
    key: 'mariana',
    name: 'Mariana',
  }),
  new User({
    key: 'marina',
    name: 'Marina',
  }),
  new User({
    key: 'oscar',
    name: 'Oscar',
  }),
  new User({
    key: 'guto',
    name: 'Guto',
  }),
];

export const getPersonNameById = (personId) => {
  return users.find((user) => user.key === personId).name;
};
