// eslint-disable-next-line
export default {
  id: 'pate_de_atum',
  type: 'savoury',
  title: 'Patê de atum',
  ingredients: [
    '1 lata de atum',
    '1/2 limão',
    '4 colheres de requeijão',
    'ketchup',
    'mostarda',
    'molho inglês',
  ],
  parts: [
    {
      title: 'Patê',
      methodOfPreparation: [
        'Tire o óleo da lata de atum',
        'Coloque o requeijão',
        'Coloque o meio limão',
        'Coloque ketchup',
        'Coloque mostarda (o gosto da mostarde é forte, então não é muito',
        'Coloque molho inglês',
        'Misture tudo com um garfo de forma que fique uniforme',
        'Va provando e adicionando um mais um pouco dos temperos de acordo com o seu gosto',
      ],
    },
  ],
};
