// eslint-disable-next-line
export default {
  id: 'bolo_de_casamento',
  type: 'sweet',
  title: 'Bolo de casamento',
  ingredients: [
    '235 gramas de manteiga',
    '400 gramas de açucar',
    '5 ovos',
    '1 colher de chá de fermento Royal',
    '8 xícaras de farinha de trigo',
    '2 cálices de vinho moscatel',
    '? nozes',
    '? passas',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Bata a farinha de trigo, o açucar, manteiga e fermento',
        'Junte os ovos batidos',
        'Junte os ingredientes secos',
        'Por último coloque o vinho',
        'Unte a forma com farinha de trigo e manteiga',
        'Leve ao forno regular',
      ],
    },
  ],
};
