// eslint-disable-next-line
export default {
  id: 'brigadeiro',
  type: 'sweet',
  title: 'Brigadeiro',
  ingredients: [
    '1 lata de leite condensado',
    '? colheres de nescau',
    '? leite ninho (Opcional)',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Derrame toda a lata de leite condensado na panela com o fogo ainda desligado (para evitar queimar)',
        'Coloque inicialmente 2 colheres de nescau e comece a misturar em fogo médio',
        'Quando a mistura ficar uniforme observe a cor',
        'O ideal é deixar marrom claro para médio escuro para não ficar muito doce. Quanto mais escuro, mais doce ele ficará',
        'Ajuste a cor de acordo com o seu gosto e sempre mexendo bem por colher adicionada para evitar colocar demais',
        'Não pare de mexer toda a panela até terminar porque se não queima',
        'O brigadeiro começará a engrossar com o passar do tempo',
        'Quando o brigadeiro estiver grossinho está pronto (geralmente assim que começa a engrossar) mas você pode tirar antes',
        'Quando estiver pronto, coloque em um recipiente raso para espalhar bem o brigadeiro e esfriar mais rápido',
        'Você pode colocar leite ninho em cima que fica bem mais gostoso',
      ],
    },
  ],
};
