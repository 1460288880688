// eslint-disable-next-line
export default {
  id: 'bolo_baiano',
  type: 'sweet',
  title: 'Bolo baiano',
  ingredients: [
    '1kg açucar',
    '200 gramas manteiga',
    '6 ovos',
    '2 xícaras de farinha de trigo',
    '2 xícaras de leite de coco puro',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Faz-se uma calda grossa de açucar',
        'Depois de morna bota-se a manteiga, os ovos (sendo 2 claras e 6 gemas)',
        'Coloca-se o leite de coco',
        'Vai-se colocando a farinha de trigo aos poucos já peneirada',
        'Unta (farinha de trigo e manteiga) na forma e leva ao forno',
      ],
    },
  ],
};
