// eslint-disable-next-line
export default {
  id: 'peixe_ao_forno',
  type: 'savoury',
  title: 'Peixe ao forno',
  ingredients: [
    '1 peixe inteiro',
    '1 colher de arisco alho e sal',
    '1 limão',
    'azeite',
    '1 cebola',
    '1 tomate',
    '2 cabeças de alho',
    '1 lata de molho de tomate',
    'Camarão (Opcional)',
    'batatas (Opcional)',
  ],
  parts: [
    {
      title: 'Peixe - véspera',
      methodOfPreparation: [
        'Limpa-se um peixe inteiro',
        'Tempera-se com uma colher de arisco alho e sal, limão e azeite (ele deve ser temperado de véspera)',
        'Guarda-o na geladeira coberto com papel de alumínio',
      ],
    },
    {
      title: 'Peixe - dia de assar',
      methodOfPreparation: [
        'Corta-se todos os temperos misturados a um bom molho de tomate',
        'Recheia sua barriga com os temperos e cobre-se o peixe formando uma cama com essas iguarias',
        'Caso queira, pode colocar dentro camarões também',
        'Cubra novamente com papel de alumínio',
        'Coloque no forno já aquecido por mais ou menos 30 minutos',
        'O caldo que o peixe solta do cozimento deve ser retirado e reservado e misturado ao mesmo um bom molho de tomate para encorpar e ser servido junto com o peixe',
        'No cozimento do peixe pode-se colocar também batatas para serem cozidas',
      ],
    },
  ],
};
