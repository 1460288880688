// eslint-disable-next-line
export default {
  id: 'pure_macaxeira',
  type: 'basic',
  title: 'Purê de macaxeira',
  ingredients: ['1kg macaxeira', '? leite', '? manteiga', '? sal'],
  parts: [
    {
      title: 'Purê de macaxeira',
      methodOfPreparation: [
        'Coloque água e sal para ferver',
        'Coloque a macaxeira',
        'Quando a macaxeira estiver transparente (ou sem estar branca), está pronta',
        'Amasse/Esprema a macaxeira numa vazilha',
        'Coloque um pouco de manteiga na panela',
        'Coloque a macaxeira',
        'Coloque leite aos poucos e mexa até virar um purê',
      ],
    },
  ],
};
