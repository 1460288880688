// eslint-disable-next-line
export default {
  id: 'arroz_de_bacalhau',
  type: 'savoury',
  title: 'Arroz de bacalhau',
  ingredients: [
    '400g de bacalhau',
    '250g de arroz',
    '1 cebola cortada em cubos',
    '4 tomates maduros sem sementes',
    '4 dentes de alho',
    'Ervilha',
    '1l de água',
    'Pimenta calabresa a gosto',
    'Sal',
    'Açafrão',
    'Azeite',
    '1 galho de manjericão',
    '1 de galho de oregano',
    '1 galho de cebolinha',
    '2 ramos de salsa',
  ],
  parts: [
    {
      title: 'Arroz de bacalhau',
      methodOfPreparation: [
        'Cozinhe o bacalhau desfiado por 15 minutos e reserve',
        'Em uma panela, coloque o azeite e deixe o alho e a cebola dourar',
        'Acrescente o bacalhau, os temperos cortados, pimenta, açafão, o buquê de ervas e o sal',
        'Misture lentamente por 10 minutos',
        'Acrescente o arroz',
        'Acrescente 4 copos de água e deixe que o arroz fique no ponto desejado',
        'Retire o buquê de ervas, decore como desejar',
      ],
    },
  ],
};
