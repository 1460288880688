// eslint-disable-next-line
export default {
  id: 'strogonoff',
  type: 'savoury',
  title: 'Strogonoff',
  ingredients: [
    'Frango',
    'Mostarda',
    'Molho inglês',
    'Sal',
    'Limão',
    '3 cabeças de alho',
    '1/2 cebola',
    '1 creme de leite',
    '1/2 lata de molho de tomate',
    'ketchup',
  ],
  parts: [
    {
      title: 'Frango',
      methodOfPreparation: [
        'Corte o frango em pedaços pequenos',
        'Tempere o frango com todos os ingredientes citados',
        'Deixe marinando por alguns minutos',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Corte as cebolas',
        'Corte os alhos',
        'Coloque as cebolas com azeite na frigideira',
        'É importante a cebola serem douradas primeiro porque o tempo para dourar é maior do que o do alho',
        'Quando a cebola começar a dourar, coloque os alhos',
        'Quando os alhos estiverem dourados coloque os frangos',
        'Fique mexendo para não queimar',
        'Quando os frangos estiverem douradinhos coloque o creme de leite e o molho de tomate',
        'Misture bem e quando tiver uniforme, coloque ketchup',
        'Misture bem novamente até ficar uniforme',
      ],
    },
  ],
};
