// eslint-disable-next-line
export default {
  id: 'bolo_de_bacia_mandioca',
  type: 'sweet',
  title: 'Bolo de bacia de mandicoa',
  ingredients: [
    '400 gramas massa de mandioca bem seca e peneirada',
    '400 gramas de manteiga boa',
    '400 gramas de açucar',
    '12 gemas',
    '1 xícara de leite de coco',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Bate-se o açucar com a massa de mandioca até ficar bem misturada e junta-se a manteiga',
        'Estando bem batido deita-se as gemas de uma em uma batendo-se sempre até ficar fofo',
        'Junta-se então uma xícara pequena de leite de coco e torna-se a bater bem',
        'Leva-se ao forno quente em forma untada de manteiga',
      ],
    },
  ],
};
