// eslint-disable-next-line
export default {
  id: 'sopa_de_tomate',
  type: 'savoury',
  title: 'Sopa de tomate',
  ingredients: [
    '8 tomates',
    '1 colher de sopa de azeite ou manteiga',
    '1 colher de sopa de farinha de trigo',
    '1 cebola',
    '? sal',
    '4 xícaras de água',
  ],
  parts: [
    {
      title: 'Sopa',
      methodOfPreparation: [
        'Refogue a cebola cortada fina no azeite',
        'Junte os tomates em pedaços e deixe refogar bem',
        'Adicione o sal e água',
        'Deixe levantar a fervura',
        'Passe na peneira e volte ao fogo',
        'Engrosse com farinha de trigo por 10 minutos',
      ],
    },
  ],
};
