// eslint-disable-next-line
export default {
  id: 'farofa_de_dede',
  type: 'savoury',
  title: 'Farofa de dendê',
  photos: 3,
  ingredients: [
    '180ml de azeite de dendê',
    '1/2 colher de chá de sal',
    '4 colheres de chá de cebola picada (bem pequena)',
    '4 xícaras de farinha de mandioca',
  ],
  parts: [
    {
      title: 'Farofa',
      methodOfPreparation: [
        'Em uma panela coloque o azeite de dendê e a cebola picada',
        'Quando a cebola dourar, vá colocando aos poucos a farinha e continue mexendo',
        'Acrescente sal',
        'Mexa por mais um/dois minutos até ficar uniforme',
      ],
    },
  ],
};
