// eslint-disable-next-line
export default {
  id: 'prawns_fried_rice',
  type: 'savoury',
  title: 'Prawns Fried Rice',
  ingredients: [
    '2 xícaras de arroz integral já cozido',
    '3 ovos',
    '200g de camarão',
    '1/2 cebola grande',
    '1 colher de sopa de alho amassado',
    '1 colher de sopa de óleo de gergelim',
    '1 xícara de shoyu',
    '2 colheres de sopa de castanha torrada',
    'Cebolinha picada',
    'Sal a gosto',
    'Pimenta do reino',
  ],
  parts: [
    {
      title: 'Prawns Fried Rice',
      methodOfPreparation: [
        'Comece fazendo os ovos mexidos na frigideira',
        'Deixe eles mais cremosinhos e reserve',
        'Na mesma frigideira, doure a cebola e o alho e reserve também',
        'Grelhe os camarões e reserve',
        'Coloque manteiga ou azeite na frigideira quente e coloque o arroz, deixe ele fritar',
        'Coloque shoyu, o óleo de gergelim e junte com os ovos, camarões e a cebola',
        'Misture tudo e acerte o sal',
        'Para finalizar, misture a castanha e a cebolinha',
      ],
    },
  ],
};
