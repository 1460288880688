// eslint-disable-next-line
export default {
  id: 'cachorro_quente',
  type: 'savoury',
  title: 'Cachorro quente',
  ingredients: [
    '1.5kg salsinha',
    '1/2kg banha',
    '500g carne de porco',
    '500g carne fresca',
    '250g presunto',
    '250g paio',
    '100g manteiga',
    '5 cebolas',
    '8 tomates',
    '2 pimentões',
  ],
  parts: [
    {
      title: 'Carne',
      methodOfPreparation: [
        'Passa-se tudo, menos a manteiga, na máquina',
        'Leva-se ao fogo com a manteiga deixando ferver um pouco',
        'Dá aproximadamente uns 60 cachorros quentes',
      ],
    },
  ],
};
