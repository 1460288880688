import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Layout from 'Layout/Layout';
import Loading from '_Shared/Loading/Loading';
const Recipe = lazy(() => import('Recipe/Recipe'));
const RecipeList = lazy(() => import('RecipeList/RecipeList'));
const SearchResult = lazy(() => import('SearchResult/SearchResult'));

function App() {
  return (
    <Router>
      <Switch>
        <Layout>
          <Suspense fallback={<Loading />}>
            <Route exact path='/procurar' component={SearchResult} />
            <Route exact path='/todos' component={SearchResult} />
            <Route exact path='/pessoa/:personId' component={RecipeList} />
            <Route
              exact
              path='/pessoa/:personId/receita/:recipeId'
              component={Recipe}
            />
            <Route exact path='/' render={() => <Redirect to='/todos' />} />
          </Suspense>
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
