// eslint-disable-next-line
export default {
  id: 'brigadeiro',
  type: 'sweet',
  title: 'Brigadeiro',
  ingredients: [
    '1 lata de leite condensado',
    '2 colheres de sopa de chocolate',
    '1 colher de chá de manteiga',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Mistura-se tudo e leva-se ao fogo',
        'Quando está fazendo bolha pode retirar do fogo',
      ],
    },
  ],
};
