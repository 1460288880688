// eslint-disable-next-line
export default {
  id: 'molho_branco',
  type: 'basic',
  title: 'Molho branco',
  ingredients: [
    '1/2 cebolas',
    '2 cabeças de alho',
    '1 creme de leite',
    '? queijo parmessão',
    '? ervas finas (Opcional)',
  ],
  parts: [
    {
      title: 'Molho',
      methodOfPreparation: [
        'Corte as cebolas',
        'Corte os alhos',
        'Coloque as cebolas com azeite na frigideira',
        'É importante a cebola serem douradas primeiro porque o tempo para dourar é maior do que o do alho',
        'Quando a cebola começar a dourar, coloque os alhos',
        'Quando os dois estiverem dourados, coloque o creme de leite',
        'Mexa até ficar uniforme',
        'Você pode colocar as ervas finas agora (Opcional)',
        'Coloque queijo parmessão a gosto (colocando aos poucos e provando)',
      ],
    },
  ],
};
