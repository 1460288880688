// eslint-disable-next-line
export default {
  id: 'tartaruguinhas',
  type: 'sweet',
  title: 'Tartaruguinha',
  ingredients: [
    '1/2 kg açucar',
    '? amêndoas',
    '? mel',
    '? noz',
    '? passas',
    '? baunilha',
    'Cravo da India',
    'palito',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Faz-se o mel em ponto de fio com baunilha',
        'Bota-se amêndoas passadas na máquina no mel misturando ligeiramente para não açucarar',
        'Faz-se as bolinhas',
        'A casca da noz serve de forma para a tartaruga',
        'Passa-se o recheio no açucar e enche-se o casco',
        'Poe-se as perninhas e a cabeça de passas enfiadas com palito',
        'A calda é feita de cravo da India',
        'Pode se fazer o recheio de brigadeiro ou olho de sogra',
      ],
    },
  ],
};
