// eslint-disable-next-line
export default {
  id: 'carne_moida',
  type: 'basic',
  title: 'Carne moida',
  ingredients: [
    '500g de carne moida',
    '1/2 cebolas',
    '2 cabeças de alho',
    '? tomate cereja',
    '? sal ou shoyo',
    '? molho ingles',
    '? ervas finas',
    '? salsinha',
    '? pimenta do reino',
  ],
  parts: [
    {
      title: 'Carne',
      methodOfPreparation: [
        'Corte as cebolas',
        'Corte os alhos',
        'Coloque as cebolas com azeite na frigideira',
        'É importante a cebola serem douradas primeiro porque o tempo para dourar é maior do que o do alho',
        'Quando a cebola começar a dourar, coloque os alhos',
        'Quando os dois estiverem dourados, coloque a carne moida',
        'Coloque o sal ou shoyo',
        'Mexa até ficar uniforme',
        'Coloque os tomates cereja cortados, as ervas finas, pimenta do reino, o molho ingles e a salsinha',
        'Fique mexendo para não queimar',
      ],
    },
  ],
};
