// eslint-disable-next-line
export default {
  id: 'pudim_de_veludo',
  type: 'sweet',
  title: 'Pudim de veludo',
  ingredients: [
    '8 colheres de sopa de açucar',
    '1 copo de leite',
    '2 colheres de sopa de açucar',
    '4 ovos sem bater',
    '1 colher farinha de trigo',
    '1 colher manteiga derretida',
    '1 pires de queijo ralado',
  ],
  parts: [
    {
      title: 'Mel',
      methodOfPreparation: [
        'Ponha o açucar numa vasilha sem água e leve ao forno mexendo até derreter',
        'Bote um pouco de água para fazer o mel grosso',
        'Vá variando a forma para ficar bem caramelado',
      ],
    },
    {
      title: 'Massa',

      methodOfPreparation: [
        'Misture os ovos com açucar, a farinha, o leite e passe na peneira',
        'Junte o quejo e a manteiga',
        'Leve ao forno com a forma forrada de mel queimado',
      ],
    },
  ],
};
