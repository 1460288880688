// eslint-disable-next-line
export default {
  id: 'salada_de_camarao',
  type: 'savoury',
  title: 'Salada de camarão',
  ingredients: [
    '150 gramas de camarão',
    '2 limōes',
    '2 dentes de alho',
    '1 xícara de macarrão parafuso integral',
    '6 brócolis (pequenos)',
    '1 cenoura',
    '6 couve flor (pequenos)',
    '2 batatas',
    '1 xícara de quinoa',
    '1 lata de atum',
  ],
  parts: [
    {
      title: 'Camarão',
      methodOfPreparation: [
        'Coloque o camarão descascado em um pote',
        'Coloque azeite',
        'Coloque sal',
        'Coloque metade de um limão',
        'Coloque pimenta do reino',
        'Coloque ervas finas',
        'Deixe o camarão marinando de 30 minutos à 1 hora',
        'Parta o alho em pedaços pequenos (você pode expreme-lo também)',
        'Depois de deixar marinar, coloque azeite em uma frigideira e espere ficar quente',
        'Coloque o alho',
        'Quando o alho ficar dourado, coloque os camarões',
        'Vire os camarões quando eles começarem a ficar alaranjados',
        'Quando os dois lados ficarem alaranjados e quando eles diminuirem o tamanho está pronto',
        'Cuidado para não deixar tempo demais e ficar borrachudo',
      ],
    },
    {
      title: 'Macarrão',
      methodOfPreparation: [
        'Coloque a água para ferver com sal',
        'Quando a água estiver fervendo coloque o macarrão',
      ],
    },
    {
      title: 'Quinoa e legumes',
      methodOfPreparation: [
        'Coloque a quinoa na panela com água o suficiente para cobrir ela duas vezes (a quantidade de água que você vê tem que ser o dobro da quantidade de qunoa que você vê)',
        'Coloque sal',
        'Coloque o brócolis, a cenoura, o couve flor e as batatas na panela da quinoa',
        'Quando não tiver mais água na panela, ela está pronta',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Num pote grande, coloque o macarrão, os legumes, a quinoa, os camarões e a lata de atum',
        'Coloque metade do limão e misture bem',
        'Coloque a outra metade (se gostar de muito limão) e misture novamente',
      ],
    },
  ],
};
