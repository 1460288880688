import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Icon } from '@material-ui/core';
import styles from 'Layout/components/header.module.scss';
import Search from 'Layout/components/Search';

export default function Header() {
  return (
    <AppBar position='fixed' className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        <Icon className={styles.logoIcon}>local_dining</Icon>
        <div className={styles.title}>Receitas Meira Lins</div>
        <Search />
      </Toolbar>
    </AppBar>
  );
}
