// eslint-disable-next-line
export default {
  id: 'canudinhos',
  type: 'savoury',
  title: 'Canudinhos',
  ingredients: [
    '100 gramas de banha',
    '200 gramas de farinha de trigo',
    '1 gema',
    '1/2 colher de sopa de manteiga',
    '1/2 xícara de água gelada com sal',
    '1 limão',
    '? camarão',
    '1 coco',
    '? coentro',
    '? cebolinho',
    '1/2 cebola',
    '? pimentão',
    '? azeite',
    '? vinagre',
    '? extrato de tomate',
    '? sal',
    '? açucar',
    '? azeitona',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Mistura-se tudo com a água com sal (aos poucos)',
        'Abre-se na máquina massa',
        'A massa não deve ser fina demais porque parte-se muito',
        'Assar no forno',
      ],
    },
    {
      title: 'Recheio',
      methodOfPreparation: [
        'Espreme o limão nos camarões',
        'De 1 coco tira-se o leite grosso com bem pouca água',
        'Passa-se as cabeças dos camarões no liquidificador com o leite do coco',
        'Peneira os camarões com leite de coco',
        'Bota-se 1/2 cebola para dorar no fogo com manteiga e azeite',
        'Coloque os temperos verdes (coentro, cebolinho e pimentão) em um pouco de vinagre e extrato de tomate',
        'Passe tudo no liquidificador',
        'Leve ao forno com 1 colher de maizena',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Pincelar com gema de ovo, uma pitada de sal e açucar',
        'Coloque o recheio dentro do canudo',
        'Coloque uma azeitona por cima do recheio',
        'Essa receita dá 60 canudos',
      ],
    },
  ],
};
