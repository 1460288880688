import RecipePart from '_Api/RecipePart';

export default class Recipe {
  constructor(recipe) {
    this.id = recipe.id;
    this.type = recipe.type;
    this.title = recipe.title;
    this.photos = recipe.photos || 1;
    this.parts = recipe.parts.map((part) => new RecipePart(part));
    this.ingredients = recipe.ingredients;
  }
}
