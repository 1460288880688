// eslint-disable-next-line
export default {
  id: 'salgadinho_de_queijo',
  type: 'savoury',
  title: 'Salgadinho de queijo',
  ingredients: [
    '250 gramas de queijo parmesão',
    '250 gramas de farinha de trigo',
    '250 gramas de margarina',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Mistura-se tudo',
        'Faz-se as bolinhas',
        'Leva-se ao forno',
      ],
    },
  ],
};
