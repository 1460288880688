// eslint-disable-next-line
export default {
  id: 'bolo_de_queijo',
  type: 'sweet',
  title: 'Bolo de queijo',
  ingredients: [
    '5 ovos',
    '3 xícaras de açucar',
    '1 margarina',
    '1 lata de creme de leite',
    '100 gramas de queijo ralado',
    '1 colher de sopa de fermento',
    '2 xícaras de farinha de trigo',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Coloque os ovos, o açucar e a margarina no liquidificador',
        'Depois de bem batido junte o creme de leite (sem soro) e o queijo e bata mais um pouco',
        'Separado ponha em uma tijela 2 xícaras de farinha de trigo e 1 colher de sopa de fermento',
        'Aos poucos junte o que ficou batido no liquidificador',
        'Leve ao forno',
      ],
    },
  ],
};
