// eslint-disable-next-line
export default {
  id: 'frango',
  type: 'basic',
  title: 'Frango',
  ingredients: [
    '1 peito de frango',
    '1 limão',
    '? sal ou shoyo',
    '? pimenta do reino',
    '? paprika',
    '? mostarda',
  ],
  parts: [
    {
      title: 'Frango',
      methodOfPreparation: [
        'Coloque limão, sal ou shoyo, pimenta do reino, um pouco de mostarda e paprika dos dois lados do frango',
        'Deixe um tempo para pegar o gosto',
        'Asse no forno aquecido por alguns minutos ou asse na frigideira ou asse na air fryer',
      ],
    },
  ],
};
