// eslint-disable-next-line
export default {
  id: 'molho_de_tomate',
  type: 'savoury',
  title: 'Molho de tomate',
  ingredients: ['1.5 kg de tomate', '2 cabeças de alho'],
  parts: [
    {
      title: 'Molho',
      methodOfPreparation: [
        'Passe os tomates no liquidificador',
        'Frita-se os alhos cortados pequenos com azeite',
        'Coloque os tomates até engrossar e cozinhar os tomates',
        'Forno muito quente',
      ],
    },
  ],
};
