// eslint-disable-next-line
export default {
  id: 'peixe_assado_ao_alho_e_limao',
  type: 'savoury',
  title: 'Peixe assado ao alho e limão',
  ingredients: [
    '3 filé de peixe',
    'Sal',
    'Pimenta do reino',
    '1 colher de sopa de manteiga derretida',
    '4 alhos espremido',
    '2 limões amarelo',
    'Ervas finas',
  ],
  parts: [
    {
      title: 'Peixe assado ao alho e limão',
      methodOfPreparation: [
        'Pré-aqueça o forno em 200°C',
        'Coloque sal e pimenta do reino nos peixes',
        'Coloque num pote a manteiga, alho, 1 limão e ervas finas e misture tudo',
        'Coloque dos dois lados do peixe com um pincel de cozinha',
        'Coloque uma rodela do limão em cima de cada peixe',
        'Deixe o peixe no forno entre 12 - 17 minutos ou até ficar douradinho',
      ],
    },
  ],
};
