// eslint-disable-next-line
export default {
  id: 'cachorro_quente',
  type: 'savoury',
  title: 'Cachorro quente',
  ingredients: [
    '500g de carne moída',
    '1 pacote com 8 salsichas',
    '1 lata de molho de tomate',
    '1/2 cebola',
    'sal',
    'salsinha',
    'alho',
  ],
  parts: [
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloque sal na carne moída',
        'Corte as salsichas em rodelas',
        'Corte as cebolas',
        'Corte os alhos',
        'Coloque as cebolas com azeite na frigideira',
        'É importante a cebola serem douradas primeiro porque o tempo para dourar é maior do que o do alho',
        'Quando a cebola começar a dourar, coloque os alhos',
        'Coloque a carne moída e misture bem',
        'Quando a carne não tiver mais vermelha, coloque o molho de tomate e as salsichas',
        'Coloque a salsinhas',
        'Deixe um pouco no forno (6 minutos) em forno baixo para apurar o gosto',
        'Desligue o forno',
        'Coloque aos poucos a mistura da panela no liquidificador',
        'Bata por poucos segundos e va mexendo o liquidificador para tudo que tiver dentro ficar uniforme',
        'Cuidado para não deixar muito tempo batendo, só são alguns segundos',
        'Coloque em um recipiente a parte a batida e repita o processo até terminar tudo da penela',
      ],
    },
  ],
};
