// eslint-disable-next-line
export default {
  id: 'moqueca_de_camarao',
  type: 'savoury',
  title: 'Moqueca de camarão',
  photos: 2,
  ingredients: [
    '1 cebola',
    '2 tomates',
    '1 pimentão vermelho',
    '1 pimentão amarelo',
    '1 pimentão verde',
    'Alho a gosto',
    'Coentro a gosto',
    '1 garrafinha de leite de coco',
    'Azeite de dendê',
  ],
  parts: [
    {
      title: 'Peixe',
      methodOfPreparation: ['Sal limão e pimenta (reserva 10min)'],
    },
    {
      title: 'Camarão',
      methodOfPreparation: ['Sal pimenta (reserva 10min'],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Com a panela quente coloca:',
        '2 colheres de azeite de dendê',
        'Cobre o fundo da panela em camadas:',
        'Cebola, tomate pimentão misturado (não põe tudo)',
        'Joga sal e pimenta',
        'Coloca o peixe cru ou o camarão',
        'Refaz as camadas de : tomate cebola e pimentões',
        'Por fim mais uma pitada de sal e pimenta e tampa a panela por 10min',
        'Coloca o leite de coco e deixa mais 5min',
      ],
    },
  ],
};
