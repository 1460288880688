// eslint-disable-next-line
export default {
  id: 'sopa_de_batata',
  type: 'savoury',
  title: 'Sopa de batata',
  ingredients: [
    '4 batatas',
    '2 cebolas',
    '4 cabeças de alho',
    'Azeite',
    '1 Couve',
  ],
  parts: [
    {
      title: 'Batatas',
      methodOfPreparation: [
        'Descasque a batata e parta em quatro partes para cozinhar mais rápido',
        'Coloque água com sal no fogo com as batatas',
      ],
    },
    {
      title: 'Tempero',
      methodOfPreparation: [
        'Corte as cebolas',
        'Corte os alhos',
        'Coloque as cebolas com azeite na frigideira',
        'É importante a cebola serem douradas primeiro porque o tempo para dourar é maior do que o do alho',
        'Quando a cebola começar a dourar, coloque os alhos',
        'Quando os dois estiverem dourados, está pronto',
      ],
    },
    {
      title: 'Couve (Opcional)',
      methodOfPreparation: ['Corte a couve em tirinhas', 'Doure com azeite'],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloque as batatas no liquidificador com um pouco da água que ela cozinhou',
        'Corte a cebola e os alhos no liquidificador',
        'Bata até ficar uniforme',
        'Você pode misturar com as couves agora (Opcional)',
      ],
    },
  ],
};
