// eslint-disable-next-line
export default {
  id: 'pasteis_de_nata',
  type: 'sweet',
  title: 'Pastéis de nata',
  ingredients: [
    '100g manteiga',
    '500g açucar',
    '1 ovo',
    '2 claras',
    '? farinha de trigo - que baste para fazer a massa de boa consistência para forrar as forminhas',
    '1 garraga leite crú',
    '12 gemas',
    '1 casca de limão verde ou baunilha',
    'sal',
  ],
  parts: [
    {
      title: 'Massa',
    },
    {
      title: 'Creme',

      methodOfPreparation: [
        'Peneira-se o creme 3 vezes',
        'Este creme não vai ao forno antes. Bota-se cru nas forminhas forradas e vai ao forno',
      ],
    },
  ],
};
