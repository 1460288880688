// eslint-disable-next-line
export default {
  id: 'batata_ao_forno_com_alecrim',
  type: 'savoury',
  title: 'Batata ao forno com alecrim',
  ingredients: [
    'batata inglesa',
    'azeite',
    'cebola',
    'sal',
    'alecrim',
    'pimenta do reino',
  ],
  parts: [
    {
      title: 'Batata ao forno com alecrim',
      methodOfPreparation: [
        'Coloque o forno para aquecer',
        'Lave a batata',
        'Corte as batatas no meio e depois cada metade em 3 pedaços',
        'Coloque as batatas numa panela com água e sal',
        'Quando começar a borbulhar a água, deixe por mais 3 minutos',
        'Corte as cebolas do mesmo jeito que a batata',
        'Coloque as batatas e as cebolas numa travessa',
        'Coloque sal, pimenta do reino e bastante azeite',
        'Pegue alguns ramos de alecrim, tire as folinhas e espalhe na travessa',
        'Coloque alguns ramos inteiros de alecrim espalhados na travessa',
        'Com o forno aquecido, coloque a travessa por 40 minutos a 220 graus',
        'Ao finalizar o tempo, retire para provar, se achar que ainda esta duro, mexa todo o conteúdo e coloque no forno novamente por mais 30 minutos',
      ],
    },
  ],
};
