// eslint-disable-next-line
export default {
  id: 'suffle_de_milho',
  type: 'savoury',
  title: 'Suffle de milho',
  ingredients: [
    '3 gemas',
    '1 colher de sobremesa de manteiga',
    '1 colher de sobremesa de maizena',
    '2 e 1/2 copos de leite',
    '3 colheres de sopa de queijo ralado',
    '1 lata de milho escorrido',
    '? sal',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Ponha a manteiga e a farinha para dourar',
        'Acrescente o leite mexendo para não embolar',
        'Acrescente as gemas, o queijo e o sal até engrossar',
        'Tire do fogo, coloque o milho, misture e leve ao forno',
      ],
    },
  ],
};
