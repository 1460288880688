// eslint-disable-next-line
export default {
  id: 'camarao',
  type: 'basic',
  title: 'Camarão',
  ingredients: [
    '500g de camarão',
    '1 limão',
    'sal',
    'azeite',
    'pimenta do reino',
    'ervas finas',
    '2 cabeças de alho (Opcional)',
  ],
  parts: [
    {
      title: 'Camarão',
      methodOfPreparation: [
        'Coloque os camarões numa tigela com todos os temperos',
        'Fique mexendo para que todos os camarões tenham o tempero',
        'Deixe os camarões temperando por 30 minutos',
        'Você vai notar que ele começou a ficar laranjinha por conta do limão',
        'Coloque azeite na frigideira em fogo quase médio',
        'Coloque os alhos cortados e espero dourar um pouco (Opcional)',
        'Coloque os camarões aos poucos para evitar que eles fiquem borrachudos',
        'Quando ele começar a ficar laranjinha, vire-os',
        'Esse processo é bem rápido, mais ou menos 1 minuto por lado',
        'Retire os camarões e coloque novos',
        'Repita o processo até acabar os camarões',
      ],
    },
  ],
};
