// eslint-disable-next-line
export default {
  id: 'picanha_ao_forno',
  type: 'savoury',
  title: 'Picanha ao forno',
  ingredients: ['4 pacotes de sal grosso', '1 picanha'],
  parts: [
    {
      title: 'Carne',
      methodOfPreparation: [
        'Cubra a assadeira formando um tapete de sal grosso',
        'Coloque a picanha com a gordura para cima, cubra a carne com sal grosso sem deixar um único pedaço sem que tenha sal. Não economize',
        'Cubra com papel alumínio a assadeira  e leve ao forno que deverá estar já aquecido',
        'O ponto de cozimento deve ser a gosto e também depende do tamanho da carne. Em média uns 40 minutos',
        'Cuidado para não assar muito, é melhor mais sangrenta e selar na frigideira do que assar demais',
      ],
    },
  ],
};
