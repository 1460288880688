// eslint-disable-next-line
export default {
  id: 'pizza',
  type: 'savoury',
  title: 'Pizza',
  ingredients: [
    '1/2 kg de farinha de trigo',
    '1 gema',
    '1 copo cheio de mais 2 dedos de leite de vaca',
    '2 colheres de chá de fermento',
    '1 colher de sopa rasa de açucar',
    '1 colher de sopa de manteiga',
    '? sal',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Faz-se a massa com umas 3 ou 4 horas de antecedência da hora de assá-la',
        '1/2 hora antes de preparar a massa dissolve-se o fermento no leite morno (não usar leite quente)',
        'Mistura-se enão todos os outros ingredientes com o leite e o fermento que já deve estar dissolvido',
        'Mistura a massa',
        'Deixa-se crescer por 3 ou 4 horas',
        'Arruma-se nas formas, espichando bem com o auxilio de um pouco de azeite para que a massa fique bem fina',
        'Coloque o recheio',
        'Leve ao forno',
        'Essa receita da para 2 pizzas grandes e 1 pequena',
      ],
    },
  ],
};
