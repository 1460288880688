// eslint-disable-next-line
export default {
  id: 'strogonoff_de_cogumelos',
  type: 'savoury',
  title: 'Strogonoff de cogumelos',
  ingredients: [
    '500g cogumelos',
    '1 cebola média',
    '2 dentes de alho',
    '1 colher de sopa de salsinha',
    '1 colher de sopa de mangerião',
    '5 colheres de azeite',
    '1 colher de sopa de mostarda',
    '1 colher de sopa de ketchup',
    'creme de leite',
    '1 tomate, picado sem casca',
    'sal',
    'pimenta do reino',
  ],
  parts: [
    {
      title: 'Strogonoff de cogumelos',
      methodOfPreparation: [
        'Em uma frigideira aquecida, refogue a cebola, junte e doure o alho no azeite',
        'Coloque os cogumelos picados e refogue',
        'Tempere com sal e pimenta do reino',
        'Acrescente as mostardas, o ketchup, o creme de leite e o tomate e deixe cozinhar',
        'Finalize com a salsinha e o manjericão',
      ],
    },
  ],
};
