// eslint-disable-next-line
export default {
  id: 'pure_de_queijo_lowcarb',
  type: 'savoury',
  title: 'Purê de queijo lowcarb',
  ingredients: [
    '500 gramas de couve-flor cozida',
    '5 colheres de sopa de creme de leite',
    'Pimenta do reino e sal a gosto',
    '2 colheres de sopa de requeijão',
    '3 fatias de queijo prato',
    '3 fatias de queijo mussarela',
  ],
  parts: [
    {
      title: 'Purê',
      methodOfPreparation: [
        'Bata a couve flor cozida com as 5 colheres de creme de leite no processador ',
        'Coloque a mistura em um pote',
        'Tempere com sal e pimenta',
        'Misture com 1 colher de sopa de requeijão, 3 fatias de queijo prato picada e as 3 fatias de queijo mussarela picada',
        'Finalize espalhando por cima a 1 colher de requeijão',
        'Leve ao forno a 240 graus por 15 minutos',
      ],
    },
  ],
};
