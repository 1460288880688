// eslint-disable-next-line
export default {
  id: 'suffle_de_chuchu',
  type: 'savoury',
  title: 'Suffle de chucu',
  ingredients: [
    '2 chuchus',
    '1 copo grande de leite',
    '1 colher de sobremesa de maizena',
    '1 colher de manteiga',
    '2 ovos',
    '2 colheres de queijo parmesão',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Mistura as gemas, o leite, maizena, o sal, a manteiga',
        'Leva ao forno até fazer um mingau ralo',
        'Cozinhe o chuchu e depois machuque com o garfo',
        'Faça neve com as claras e coloque em cima da massa com queijo ralado',
      ],
    },
  ],
};
