// eslint-disable-next-line
export default {
  id: 'medalhoes_de_mignon_salteados_na_manteiga',
  type: 'savoury',
  title: 'Medalhões de mignon salteados na manteiga',
  ingredients: [
    'Molho inglês a gosto',
    '1kg filé mignon (limpo e cortado em medalhões)',
    '5 dentes alho',
    '70g manteiga ',
    'pimenta-do-reino a gosto',
    'Sal a gosto',
    'Um fio de azeite',
  ],
  parts: [
    {
      title: 'Filé de tilápia  na verdura ',
      methodOfPreparation: [
        'Pegue o filé, faça furos e tempere com o molho inglês, sal, pimenta e um dente de alho amassado',
        'Reserve por uma hora para absorver o tempero',
        'Amasse os demais dentes de alho',
        'Pegue uma frigideira e derreta a manteiga',
        'Em seguida, doure os dentes de alho amassados',
        'Quando o alho começar a ficar amarelinho, coloque os os medalhões',
        'Frite nos dois lados até o pronto desejado',
        'Sirva ainda quente',
      ],
    },
  ],
};
