// eslint-disable-next-line
export default {
  id: 'nhoque',
  type: 'basic',
  title: 'Nhoque',
  ingredients: ['1 pacote de nhoque', '? sal', '1 molho de tomate'],
  parts: [
    {
      title: 'Nhoque',
      methodOfPreparation: [
        'Coloque a água para ferver com sal',
        'Quando a água estiver fervendo, coloque o nhoque',
        'Quando eles começarem a boiar, remova os nhoques que estiverem boiando',
        'É um processo bem rápido, então fique atento(a)',
        'Quando você retirou todos, está pronto',
        'Você pode colocar dentro de um molho de tomate ou molho branco quente e está pronto',
      ],
    },
  ],
};
