// eslint-disable-next-line
export default {
  id: 'arroz_doce',
  type: 'sweet',
  title: 'Arroz doce',
  ingredients: [
    '1 xícara de arroz rasa',
    '1 xícara de açucar',
    '1 coco',
    '1 casca de limão',
    '? sal',
    '? leite',
  ],
  parts: [
    {
      title: 'Arroz',
      methodOfPreparation: [
        'Tira-se o leite grosso com 1 xícara de água',
        'No bagaço bota-se mais 2 xícaras de água e tira-se o leite ralo',
        'Bota-se no fogo o arroz, o leite ralo, o sal e a casca de limão',
        'Quando o arroz estiver cozido bate-se o leite grosso com o açucar deixando ferver um pouco',
      ],
    },
  ],
};
