// eslint-disable-next-line
export default {
  id: 'salmao_com_salada_de_quinoa',
  type: 'savoury',
  title: 'Salmão com salada de quinoa',
  ingredients: [
    'Salmão',
    'Quinoa',
    'Aspargos',
    'Alho poró',
    'Sal',
    'Limão',
    'Teriaki (Opcional)',
  ],
  parts: [
    {
      title: 'Salmão',
      methodOfPreparation: [
        'Tempere o salmão com sal, limão e ervas finas',
        'Pré-aqueça o forno a 180 graus',
        'Quando o forno tiver quente, coloque o salmão',
        'Retire quando tiver dourado em cima',
        'Coloque o terikai em cima do salmão se quiser',
      ],
    },
    {
      title: 'Salada de quinoa',
      methodOfPreparation: [
        'Corte o alho poró e os aspargos em pequenos pedaços',
        'Pré-aqueça duas panelas de água com sal',
        'Coloque a quinoa (é o dobro de água para a quantidade de quinoa) em uma das panelas e deixe até a água evaporar',
        'Coloque os aspargos e o alho poró cortados dentro da água por 5 minutos e depois escoa a água',
        'Quando os dois estiverem prontos, misture os aspargos, o alho poró e a quinoa em uma frijideira com um pouco de azeite por 1 minuto',
      ],
    },
  ],
};
