// eslint-disable-next-line
export default {
  id: 'tapioca',
  type: 'basic',
  title: 'Tapioca',
  ingredients: [
    '4 colheres de massa de tapioca',
    '? sal',
    'recheio que você quiser',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Pegue uma frigideira do tamanho que você quer a tapioca',
        'Aqueça a frigideira (não coloque a massa até a frigideira estiver quente)',
        'Coloque a massa de forma que cubra totalmente a frigideira',
        'Organize a massa na frigideira para ficar em uma só altura',
        'Coloque mais massa se necessário para cubrir buracos',
        'Coloque sal',
        'Não mexa mais na massa',
        'Quando ela estiver unida abaixe o fogo',
        'Coloque o recheio',
        'Aumente um pouco o fogo',
        'Deixe um pouco mais de tempo a depender do recheio, mas cuidado para não queimar',
        'Se for fazer outra tapioca, limpe a frigideira para evitar que fique com gosto de queimado a próxima',
      ],
    },
  ],
};
