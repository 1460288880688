import React, { useState, useEffect } from 'react';
import Photo from 'Layout/components/Photo';
import { Link } from 'react-router-dom';
import api from '_Api/api';
import styles from 'Layout/components/peopleDrawer.module.scss';
import { Icon } from '@material-ui/core';

export default function PeopleDrawer() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(api.getUsers());
  }, []);

  const renderPerson = (user, index) => {
    const activeClass = getActiveClass(user, index);

    return (
      <Link
        key={user.key}
        to={`/pessoa/${user.key}`}
        className={`${styles.person} ${activeClass}`}
      >
        <Photo personId={user.key} />
      </Link>
    );
  };

  const getActiveClass = (user, index) => {
    const currentPath = window.location.pathname;
    const isSearch = currentPath.includes('procurar');

    if (isSearch) {
      return false;
    }

    const isActive = currentPath.includes(`/${user.key}`);
    const isHomeAndFirst = currentPath === '/' && index === 0;

    return isActive || isHomeAndFirst ? styles.active : '';
  };

  return (
    <div className={styles.container}>
      <Link
        key='all'
        to='/todos'
        className={`${styles.all} ${getActiveClass({ key: 'todos' }, 0)}`}
      >
        <Icon>groups</Icon>
      </Link>
      {users.map((user, index) => renderPerson(user, index + 1))}
    </div>
  );
}
