// eslint-disable-next-line
export default {
  id: 'cachorro_quente',
  type: 'savoury',
  title: 'Cachorro quente',
  ingredients: [
    '1Kg de salsicha cozida e moída',
    '1/5kg de carne moída',
    '2 dentes de alho',
    '1 Paio moído',
    '2 calabresas moídas',
    '100g de bacon moído',
    '600 ml de molho de tomate',
    'Fio de azeite',
    'Pimenta, cominho, páprica a gosto',
  ],
  parts: [
    {
      title: 'Cachorro quente',
      methodOfPreparation: [
        'Em uma panela doure o olho com o azeite e misture a carne moída, temperos, calabresa, bacon e o paio;',
        'Quando a carne estiver dourada acrescente a salsicha e molho;',
        'Deixe ferver por 20 minutos.',
      ],
    },
  ],
};
