// eslint-disable-next-line
export default {
  id: 'risoto_de_quejo',
  type: 'savoury',
  title: 'Risoto de Queijo',
  ingredients: [
    '2 cebolas (1 para o caldo e 1 para o risoto)',
    '2 tomate pelado picado (1 para o caldo e 1 para o risoto)',
    '1 Alho picado ou 1 colher de chá de alho',
    'Azeite',
    'Manteiga',
    '1 e 1/2 xícara de arroz arbóreo',
    '100g de queijo parmesão ralado',
    '1 xícara de molho de tomate',
    'Verduras para tempero (Coentro, tomate, cebolinho, cebola)',
  ],
  parts: [
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloca as verduras de tempero junto com caldo de legumes e um pouco de sal numa panela, e refoga tudo',
        'Quando tiver soltando um líquido e bem quente coloca água até a bota e deixa em fogo médio a água ferver.',
        'Enquanto a água do caldo está fervendo pega uma panela (a msm que vai fazer o risoto) coloca manteiga, alho e um fio de azeite',
        'Coloca o camarão descasado por 2 minutinhos (ele vai terminar de cozinhar no arroz)',
        'Retira o camarão e na msm panela( sem limpar) coloca manteiga( caprichado) e cebola e refoga a cebola com uma pitadinha de sal (pouco)',
        'Depois coloca uma o arroz e uma xícara de vinho branco. E fica mexendo',
        'Quando secar vc vai colocando de 2 em 2 conchas do caldo da cabeça do camarão( tem que estar fervendo, deixa em fogo baixo nessa hora) por aprox 10 min',
        'Após 10 min, coloca o tomate picado e continua mexendo com o caldo da cabeça',
        'Vai provando o ponto do arroz, quando ele tiver ao dente, vc coloca o camarão, as duas xícaras de molho de tomate e mexe por mais 1 min',
        'Depois coloca uma colher de manteiga o queijo parmesão, desliga o fogo e fecha a panela por 2 min',
      ],
    },
  ],
};
