// eslint-disable-next-line
export default {
  id: 'moqueca_de_camarao',
  type: 'savoury',
  title: 'Moqueca de camarão',
  ingredients: [
    '500g camarões',
    '1 limão',
    'sal',
    'pimenta do reino',
    'azeite',
    '1 tomate',
    '1 cebola',
    '1 pimentão vermelho',
    '1 pimentão verde',
    '1 pimentão amarelo',
    'coentro',
    '3 cabeças de alho',
    '250ml de leite de coco',
    '1 xícara molho de tomate',
    '2 colheres de chá de óleo de dendê (mas a gosto)',
  ],
  parts: [
    {
      title: 'Camarões',
      methodOfPreparation: [
        'Coloque os camarões em uma tigela com limão, sal, pimenta do reino e azeite',
        'Deixe marinando por 30 minutos',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloque a cebola para refogar',
        'Coloque os alhos para refogar depois que a cebola estiver ficando dourada',
        'Coloque o tomate e os pimentões',
        'Coloque o molho de tomate',
        'Coloque o leite de coco',
        'Coloque sal',
        'Misture até ficar uniforme',
        'Deixe o leite de coco cozinhar um pouco',
        'Coloque os camarões com o molho que foi gerado quando ele estava marinando e espere cozinhar um pouco',
        'Quando eles começarem a ficar laranjinhas, coloque o óleo de dendê e o coentro',
        'Misture bem e vá provando para saber se você quer colocar mais de algum tempero',
        'Espere o camarão cozinhar no molho (uns 15 minutos) e está pronto',
      ],
    },
  ],
};
