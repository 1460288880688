// eslint-disable-next-line
export default {
  id: 'salada_feta',
  type: 'savoury',
  title: 'Salada de feta',
  ingredients: [
    '? macarrão',
    '? sal',
    '? pimenta do reino',
    '? queijo feta',
    '? cenoura',
    '? cebolinha',
    '? quinoa',
  ],
  parts: [
    {
      title: 'Quinoa',
      methodOfPreparation: [
        'Coloque a quinoa numa panela',
        'Coloque o dobro de água da quantidade de quinoa',
        'Coloque sal',
        'Quando a água secar, esta pronta',
      ],
    },
    {
      title: 'Cenoura',
      methodOfPreparation: [
        'Coloque a cenoura numa panela cheia de água',
        'Coloque sal',
        'Quando a cenoura estiver mole, está pronta',
      ],
    },
    {
      title: 'Macarrão',
      methodOfPreparation: [
        'Coloque o macarrão numa panela cheia de água',
        'Coloque sal',
        'Quando o macarrão estiver semi mole, está pronto',
      ],
    },
    {
      title: 'Assar cenoura com cebolinha',
      methodOfPreparation: [
        'Coloque a cebolinha e cenoura numa frigideira',
        'Asse um pouco para uma pegar o sabor da outra',
        '(Opcional) Se tiver achando se graça, coloque um pouco de molho shoyo',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Misture tudo num pote',
        'Corte o queijo feta em quadradinhos',
        'Misture o queijo na salada',
        'Esprema um limão e misture bem',
      ],
    },
  ],
};
