// eslint-disable-next-line
export default {
  id: 'bacalhau_com_natas',
  type: 'savoury',
  title: 'Bacalhau com natas',
  ingredients: [
    '4 files de bacalhau',
    '1 kg batata',
    '2 cebola branca',
    'Alho',
    '250ml Leite de amêndoa',
    '500ml Creme de leite (300ml Double cream and 200ml Extra thick cream)',
    'Maizena',
    'Azete',
    'Sal',
    'pimenta do reino',
    'Caldo de vegetais',
  ],
  parts: [
    {
      title: 'Bacalhau com natas',
      methodOfPreparation: [
        'Descasque e corte as batatas mantendo-as na água',
        'Frite a batata fatiada por 5 minutos',
        'Ferva por 15 minutos o caldo de vegetais com água',
        'Salgue o bacalhau',
        'Frite a cebola e o alho até dourar',
        'Acrescente os filetes de bacalhau',
        'Acrescente o leite de amêndoa e o creme de leite',
        'Espere ferver',
        'Coloque tudo junto em uma bandeja e leve ao forno por 20 minutos',
        'Adicione a maizena conforme necessário para engrossar (pelo menos 2 colheres de chá misturadas com uma pequena quantidade de água fria)',
        'Adicione o parmesão por cima e asse por mais 10 minutos',
      ],
    },
  ],
};
