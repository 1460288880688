import React from 'react';
import PropTypes from 'prop-types';
import styles from 'Layout/components/photo.module.scss';

export default class Photo extends React.PureComponent {
  static propTypes = {
    personId: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
  };

  static defaultProps = {
    size: 'small'
  };

  render() {
    const { size, personId } = this.props;

    return (
      <div className={`${styles.container} ${styles[size]}`}>
        <img
          aria-label='recipe'
          className={styles.circle}
          src={require(`./_images/${personId}.jpg`).default}
          alt='Foto de perfil'
        />
      </div>
    );
  }
}
