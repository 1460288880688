// eslint-disable-next-line
export default {
  id: 'broas_de_goma',
  type: 'sweet',
  title: 'Broas de goma',
  ingredients: [
    '1kg goma',
    '1 colher de manteiga',
    '2 gemas',
    '1 coco ou mais de um sendo que o leite é puro',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Mistura-se tudo e vai se colocando o leite até a massa ficar consistente',
        'Bate-se durante muito tempo',
        'Só fica em pouco certo certo quando toma a forma de bolinha',
      ],
    },
  ],
};
