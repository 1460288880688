// eslint-disable-next-line
export default {
  id: 'arroz',
  type: 'basic',
  title: 'Arroz',
  ingredients: [
    '1 xícara de arroz',
    '3 xícaras de água',
    '4  cabeças de alho',
    '1/2 cebola',
    'sal',
  ],
  parts: [
    {
      title: 'Arroz',
      methodOfPreparation: [
        'Corte as cebolas',
        'Corte os alhos',
        'Coloque as cebolas com azeite na frigideira',
        'É importante a cebola serem douradas primeiro porque o tempo para dourar é maior do que o do alho',
        'Quando a cebola começar a dourar, coloque os alhos',
        'Quando os dois estiverem dourados, coloque o arroz e misture bem',
        'Coloque as xícaras de água, sal e tampe a panela',
        'Coloque em fogo quase médio',
        'Quando a água secar o arroz está pronto',
      ],
    },
  ],
};
