// eslint-disable-next-line
export default {
  id: 'carne_batata_brocolis',
  type: 'savoury',
  title: 'Carne com batata ao forno e brócolis',
  ingredients: [
    'Batata inglesa',
    'Azeite',
    'Sal',
    'Pimenta do reino',
    'Sal grosso',
    '1/2 cebola roxa',
    '1 pedaço de carne',
    'Molho inglês',
    'Mel',
    'Brócolis',
    '2 dentes de alho',
  ],
  parts: [
    {
      title: 'Batata ao forno',
      methodOfPreparation: [
        'Coloque o forno para aquecer',
        'Lave a batata',
        'Corte as batatas no meio e depois cada metade em 3 pedaços',
        'Coloque as batatas numa panela com água e sal',
        'Quando começar a borbulhar a água, deixe por mais 3 minutos',
        'Coloque as batatas numa travessa',
        'Coloque sal, pimenta do reino e azeite',
        'Com o forno aquecido, coloque a travessa por 30 minutos a 220 graus',
      ],
    },
    {
      title: 'Carne',
      methodOfPreparation: [
        'Coloque sal dos dois lados da carne',
        'Quando a frigideira estiver bem quente coloque cebola roxa',
        'Quando tiver assada, coloque a carne e logo em seguida vire a carne para evitar que saia líguido dela',
        'Coloque em forno médio para baixo, se não queimará por fora e ficará cru por dentro',
        'Coloque alecrim e tampe a carne',
        'Quanto mais grossa, mais difícil é de acertar o ponto de forma que não fique crua dentro',
        'Você pode cortar em cima da carne para checar se ta crua ou furar, se sair sangue é porque está crua',
        'Fazendo vários retalhos dos dois lados faz com que a carne asse melhor por dentro',
        'Fique virando a carne até ficar pronta',
      ],
    },
    {
      title: 'Brócolis',
      methodOfPreparation: [
        'Coloque sal azeite na frigideira',
        'Coloque o alho espremido',
        'Deixe assando um pouco',
        'Coloque os brócolis',
        'Tampe-os e deixe por 2 minutos e meio',
        'Vire-os e deixe por mais 2 minutos e meio (tampados)',
        'Coloque sal e limão',
        'Sirva imediatamente',
      ],
    },
  ],
};
