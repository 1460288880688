// eslint-disable-next-line
export default {
  id: 'carne',
  type: 'basic',
  title: 'Carne',
  ingredients: [
    '? Sal',
    '1 pedaço de carne',
    '2 cabeças de alho (Opcional)',
    '1/2 cebola (Opcional)',
  ],
  parts: [
    {
      title: 'Carne',
      methodOfPreparation: [
        'Coloque sal dos dois lados da carne',
        '(Opcional) Corte as cebolas, corte os alhos. Coloque as cebolas com azeite na frigideira. Quando a cebola começar a dourar, coloque os alhos',
        'Coloque a carne',
        'Coloque em forno médio',
        'Asse dos dois lados',
        'Quanto mais grossa, mais difícil é de acertar o ponto de forma que não fique crua dentro',
      ],
    },
  ],
};
