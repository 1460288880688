export const getRecipesContext = (personId) => {
  switch (personId) {
    case 'anamaria':
      return require.context('./', true, /anamaria\/([^\s]*).js/);
    case 'marina':
      return require.context('./', true, /marina\/([^\s]*).js/);
    case 'lucia':
      return require.context('./', true, /lucia\/([^\s]*).js/);
    case 'mariana':
      return require.context('./', true, /mariana\/([^\s]*).js/);
    case 'gabi':
      return require.context('./', true, /gabi\/([^\s]*).js/);
    case 'oscar':
      return require.context('./', true, /oscar\/([^\s]*).js/);
    case 'marcella':
      return require.context('./', true, /marcella\/([^\s]*).js/);
    case 'guto':
      return require.context('./', true, /guto\/([^\s]*).js/);
    default:
      return require.context('./', true, /marina\/([^\s]*).js/);
  }
};
