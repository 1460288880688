// eslint-disable-next-line
export default {
  id: 'bolo_de_rolo',
  type: 'sweet',
  title: 'Bolo de rolo',
  ingredients: [
    '400 gramas de farinha de trigo',
    '400 gramas de manteiga',
    '400 gramas de açucar',
    '12 ovos',
    '1 colher de fermento',
    '1 lata de goiabada',
    '? queijo do reino',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Bate-se bem a manteiga com o açucar',
        'Mistura-se os ovos batidos com a farinha de trigo e o fermento',
        'Depois de tudo misturado, leve ao forno com a forma untada (manteiga e farinha de trigo)',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Em cima da massa coloca-se em cima a goiabada derretida e queijo do reino ralado',
        'Enrole a massa',
        'Coloque açucar em cima',
      ],
    },
  ],
};
