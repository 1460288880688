// eslint-disable-next-line
export default {
  id: 'torta_de_maca',
  type: 'sweet',
  title: 'Torta de maçã',
  ingredients: [
    '10 colheres de farinha de trigo',
    '6 colheres de açucar',
    '3 colheres de manteiga',
    '1 colher de pó Royal',
    '2 gemas',
    '? sal',
    '1kg de maçã feito compota',
    '1 gema',
    '1 colher de maizena',
    '1 colher de chá de baunilha',
    '500 ml de leite',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Peneira-se a farinha com o açucar e o pó Royal',
        'Bate-se a manteiga, as gemas e se amassa',
        'Coloca a massa na forma e leva-se ao forno para inchar',
      ],
    },
    {
      title: 'Creme',
      methodOfPreparation: [
        'Misture tudo e coloque ao forno junto com a massa depois que a massa inchar',
      ],
    },
  ],
};
