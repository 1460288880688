// eslint-disable-next-line
export default {
  id: 'torta_de_atum',
  type: 'savoury',
  title: 'Torta de atum',
  ingredients: [
    '3 ovos',
    '1 pitada de sal',
    '1 copo de iorgute natural',
    '150 gramas de queijo mussarela (pode ser mussarela de búfala)',
    '1 colher de chá de fermento',
    '1 lata de atum (pode ser outros recheios palmito, Frango, carne, camarão etc)',
    '1 tomate',
    '1/2 cebola',
    '2 colheres de azeite',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Bate os ovos no liquidificador',
        'Adiciona o resto dos ingredientes no liquidificador e bate',
      ],
    },
    {
      title: 'Recheio e finalização',
      methodOfPreparation: [
        'Unta a travessa como fosse assar um bolo',
        'Coloque metade da massa na travessa untada',
        'Coloque o recheio em cima da massa',
        'Coloque o resto da massa em cima do recheio',
        'Asse por 35 minutos',
      ],
    },
  ],
};
