// eslint-disable-next-line
export default {
  id: 'peixe_ao_molho_de_coco_e_alho_poro',
  type: 'savoury',
  title: 'Peixe ao molho de coco e alho-poró',
  ingredients: [
    '4 filés de tilápia',
    '400 ml de leite de coco',
    '2 talos de alho poró cortado em rodelas',
    '2 pimentas dedo de moça partida em pequenos pedaços',
    'Pimenta do reino a gosto',
    'Sal a gosto',
    '1/2 cebola partida em pedaços de tamanho médio',
    '2 tomates médios partido em pedaços de tamanho médio',
    '1 limão',
    'Coentro (Opcional)',
  ],
  parts: [
    {
      title: 'Peixe ao molho de coco e alho-poró',
      methodOfPreparation: [
        'Pré-aqueça o forno em 180°C',
        'Tempere o peixe com o sal, limão e pimenta do reino',
        'Numa frigideira aqueça o azeite de oliva e coloque a cebola',
        'Quando a cebola estiver dourando, acrescente o tomate e o alho poró',
        'Acrescente o leite de coco, a pimenta de moça e o coentro',
        'Deixe por 5 minutos',
        'Em uma outra assadeira, asse os filés',
        'Quando os peixes estiverem assado, os coloque dentro do molho de coco',
        'Deixe por mais 5 minutos',
        'Coloque tudo em uma travessa',
        'Leve ao forno a 180 graus por 10 min',
      ],
    },
  ],
};
