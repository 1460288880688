// eslint-disable-next-line
export default {
  id: 'vatapa',
  type: 'savoury',
  title: 'Vatapá',
  ingredients: [
    '3kg peixe sem pele',
    '1kg camarão',
    '1 lata de extrato de tomate',
    '1 lata pequena de azeite português',
    '300g castanha',
    '3 cocos grandes (leite grosso)',
    '200g tomate',
    '200g cebola',
    '1 colher de sopa rasa de pimenta do reino',
    '2 colheres de vinagre',
    '4 colheres de dendê',
    '? sal',
    '1 pouco de gengibre ralado',
    '4 pães integrais dos grandes',
  ],
  parts: [
    {
      title: 'Creme',
      methodOfPreparation: [
        'Molhe os pães no o leite ralo e passe no liquidificador com as castanhas',
        'Faça os camarões e o peixe com os temperos',
        'Misture os camarões, o peixe, os pães com castanha batidos no liquidificador numa panela',
        'Coloque o azeite de dendê e mexa sempre',
        'Durante o tempo que estiver cozinhando vai-se juntando o azeite doce e o extrato de tomate que sobrou do peixe e do camarão',
      ],
    },
  ],
};
