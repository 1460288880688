// eslint-disable-next-line
export default {
  id: 'molho_de_tomate',
  type: 'savoury',
  title: 'Molho de tomate',
  photos: 6,
  ingredients: [
    '6 Tomates',
    '4 dentes de alho',
    '1 cebolinha',
    'Sal',
    'Pimenta',
    'Azeite',
  ],
  parts: [
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Tira a pele. (Faz um X no fundo, joga água quente depois água fria. E pell)',
        'Corta em 6 partes (Pode ser tomate pelado em pala também)',
        '6 tomates, 4 dentes de alho e talos de cebolinha (pode trocar por algo poro)',
        'Joga por cima :',
        'Sal a gosto',
        'Pimenta a gosto',
        'Azeite a gosto',
        '20 min no forno até dourar os tomates (pode ser mais tempo ou menos, depende da temperatura. Mas deixa ficar douradinho)',
        'Depois mixar. Pronto',
      ],
    },
  ],
};
