// eslint-disable-next-line
export default {
  id: 'arroz_de_coco',
  type: 'savoury',
  title: 'Arroz de coco',
  ingredients: [
    '250 ml de leite de coco',
    '250 ml de água fervente',
    '2 xícara (chá) de arroz cru, lavado e escorrido',
    '1/2 colher (chá) de sal',
  ],
  parts: [
    {
      title: 'Arroz de coco',
      methodOfPreparation: [
        'Em uma panela média, coloque o leite ralo (metade do leite de coco misturado com toda a água), o arroz e o sal, e cozinhe em fogo médio',
        'Deixe por 5 minutos após o início da fervura com a panela semitampada',
        'Junte o leite espesso e deixe cozinhar por mais 5 minutos, ou até que o arroz esteja macio',
        'Retire do fogo e sirva em seguida',
      ],
    },
  ],
};
