// eslint-disable-next-line
export default {
  id: 'torta_de_limao',
  type: 'sweet',
  title: 'Torta de limão',
  ingredients: [
    '4 colheres de sopa de manteiga',
    '2 colheres de sopa de açucar',
    '2 colheres de sopa de leite em pó',
    '2 gemas',
    '1 colher de chá de sal',
    '? farinha de trigo',
    '1 late de leite condensado',
    '1 xícara de café cheia de sumo de limão',
    '1 lata de leite de vaca (a lata do leite condensado)',
    '2 colheres de sopa de maizena',
    '2 claras batidas',
    '4 colheres de sopa de açucar',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Junte os ingredientes com a farinha de trigo até a massa soltar',
      ],
    },
    {
      title: 'Recheio',
      methodOfPreparation: ['Bate-se tudo no liquidificador'],
    },
    {
      title: 'Suspiro',
      methodOfPreparation: ['Bata até ficar uniforme'],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloque na forma untada (manteiga e farinha de trigo) a massa, depois o recheio e por último o suspiro',
        'Leve ao forno',
      ],
    },
  ],
};
