// eslint-disable-next-line
export default {
  id: 'macarrao',
  type: 'basic',
  title: 'Macarrão',
  ingredients: ['macarrão', 'sal', 'azeite'],
  parts: [
    {
      title: 'Macarrão',
      methodOfPreparation: [
        'Coloque água para ferver',
        'Quando começar a ferver, coloque sal',
        'Coloque o macarrão',
        'Use um garfo para mexer o macarrão para ele não grudar',
        'Quando o macarrão começar a ficar mole prove para saber se o ponto dele está bom para você',
      ],
    },
  ],
};
