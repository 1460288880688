// eslint-disable-next-line
export default {
  id: 'biscoitos_casadinhos',
  type: 'sweet',
  title: 'Biscoitos casadinhos',
  ingredients: [
    '200 gramas manteiga',
    '100 gramas açucar',
    '300 gramas farinha de trigo',
    '? sal',
    'Doce de goiaba',
  ],
  parts: [
    {
      title: 'Massa',
      methodOfPreparation: [
        'Amasse tudo com um pouco de sal',
        'Corte com um cálice',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Junta-se 2 a 2 biscoitos com doce de goiaba',
        'Leve ao forno',
        'Dá aproximadamente 60 biscoitos',
      ],
    },
  ],
};
