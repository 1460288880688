// eslint-disable-next-line
export default {
  id: 'salmao_na_manteiga_com_ervas',
  type: 'savoury',
  title: 'Salmão na manteiga com ervas',
  ingredients: [
    '450g de Salmão (aproximadamente)',
    '1 colher de sopa de manteiga',
    '2 dentes de alho picado',
    '2 colheres de sopa de rrvas finas',
    '1/2 limão',
    'Sal a gosto',
    'Pimenta do reino a gosto',
  ],
  parts: [
    {
      title: 'Purê',
      methodOfPreparation: [
        'Lave o salmão com água morna e meio limão (se desejar)',
        'Derreta a manteiga (reserve)',
        'Em uma assadeira forre o papel alumínio deixando sobra de forma que você consiga cobrir todo o salmão',
        'Coloque o salmão na assadeira com a pele para baixo',
        'Pincele a manteiga em todo o peixe, coloque o sal, o alho picado, as ervas finas e a pimenta',
        'Cubra o salmão com o papel alumínio e leve ao forno pré aquecido por aproximadamente 20 minutos',
      ],
    },
  ],
};
