// eslint-disable-next-line
export default {
  id: 'tomate_confit',
  type: 'savoury',
  title: 'Tomate Confit',
  ingredients: [
    '500 g de tomate cereja',
    '2 dentes de alho picados em rodelas',
    '1 pitada de sal a gosto',
    '1 xícara de azeite extra virgem',
    'orégano a gosto',
    'Alecrim a gosto',
    'Ervas finas a gosto',
    'Pimenta calabresa a gosto',
    'Pimenta do reino a gosto',
    'Manjericão a gosto',
  ],
  parts: [
    {
      title: 'Tomate Confit',
      methodOfPreparation: [
        'Lavar os tomates e secar com papel toalha, um a um',
        'Cortá-los na metade, em sentido de comprimento',
        'Colocá-los em uma assadeira com o fundo coberto de azeite',
        'Espalhar os dentes de alho fatiados por cima dos tomates',
        'Temperar a gosto com ervas',
        'Acrescentar a pitada de sal a gosto',
        'Regar com o resto da xícara de azeite',
        'Levar ao forno em potência alta, até os tomates ficarem murchos, mas inchados de azeite',
        'Ao tirar do forno finalize com as folhas de manjericão',
        'Servi-los com carnes, massas ou pão',
      ],
    },
  ],
};
