// eslint-disable-next-line
export default {
  id: 'moqueca',
  type: 'savoury',
  title: 'Moqueca',
  ingredients: [
    '700 gr de peixe fresco em postas',
    '1kg de camarão',
    '300g de casca de camarão sem os olhos e sem as antenas',
    '500 ml de leite de coco',
    '4 dentes de alho picado',
    '2 + 1/2 tomates grandes',
    '1 + 1/2 cebola grande',
    '1 pimentão amarelo',
    '1 pimentão verde',
    '1 pimentão vermelho',
    '1 colher (café) de sal',
    '5 colheres (sopa) de dendê',
  ],
  parts: [
    {
      title: 'Moqueca',
      methodOfPreparation: [
        'Em uma frigideira funda coloque uma colher de azeite de dendê, com meia cebola picada ,meio tomate picado e um dente de alho picado até dourar',
        'Acrescente as cascas do camarão, quando dourar acrescente 100ml de leite de coco',
        'Misture tudo e deixe ferver em fogo baixo por 10 minutos',
        'Quando esfriar, passe tudo no processador e penere. Reserve',
        'Coloque as postas de peixe em cubinhos em uma travessa, tempere com sal, alho. Reserve',
        'Corte os tomates e as cebolas em rodelas grossas, retire as sementes do pimentão e corte em fatias grossas',
        'Na panela coloque um pouco de azeite de dendê, Forre a panela com os tomates, os pimentões, a cebola. Acrescente um pouco de sal',
        'Essa base irá proteger o peixe para que ele não grude na panela',
        'Pegue os pedaços do peixe e faça alguns furos com uma faca afiada para que o tempero penetre. Coloque o peixe sobre os vegetais, regue com leite de coco, o líquido temperados que foi feito anteriormente e acrescente o dendê',
        'Deixe a moqueca marinando por 15 minutos',
        'Em seguida leve a panela ao fogo e deixe cozinhar com a panela tampada por 20 minutos. Em seguida, adicione os camarões, não é necessário temperá-los, e cozinhe por mais 5 minutos',
        'Sirva com arroz branco e farofa',
      ],
    },
  ],
};
