// eslint-disable-next-line
export default {
  id: 'file_de_tilapia_na_verdura',
  type: 'savoury',
  title: 'Filé de tilápia  na verdura',
  ingredients: [
    '1kg de filé tilápia (ou outro peixe de sua preferência)',
    '1 Cebola grande',
    '1 tomate grande',
    '4 Dentes de alho picado ',
    '1 Pimentão vermelho',
    '1 Pimentão verde',
    '1 Pimentão amarelo',
    '1/2 xícara de molho de tomate',
    '1/2 xícara de azeite de oliva/trufado',
    'Pimenta do reino branca (a gosto)',
    'Pimenta do reino preta( a gosto)',
    'Sal (a gosto)',
    'Cominho (a gosto)',
  ],
  parts: [
    {
      title: 'Filé de tilápia  na verdura',
      methodOfPreparation: [
        'Lave o peixe com água quente',
        'Em um recipiente tempere os filés do peixe com azeite, sal, pimenta, cominho e um dente de alho amassado',
        'Reserve por pelo menos uma hora',
        'Corte a cebola, os pimentões e o tomate em rodelas e amasse os demais dentes de alho',
        'Tempere com sal, pimenta e um pouco de azeite, sempre misturando, para que o tempero fique uniforme',
        'Reserve por pelo menos hora',
        'Em uma frigideira coloque um pouco do azeite. Quando esquentar, refogue as verduras.',
        'Quando as verduras ficarem douradas coloque os filés para grelhar, colocando sempre que necessário um pouco mais de azeite e o molho de tomate, para eles absorverem o sabor das verduras refogadas',
        'Coloque o peixe em uma travessa de servir e por cima arrume os vegetais refogado',
      ],
    },
  ],
};
