// eslint-disable-next-line
export default {
  id: 'pure_de_alho',
  type: 'basic',
  title: 'Purê de alho',
  ingredients: [
    '2 batatas inglesas',
    '3 cabeças de alho',
    '1/2 copo de leite',
    'Azeite',
    'Sal',
  ],
  parts: [
    {
      title: 'Batata',
      methodOfPreparation: [
        'Descasque a batata e parta em quatro partes para cozinhar mais rápido',
        'Coloque água com sal no fogo com as batatas',
        'Você pode provar a água para ter noção do sal',
        'Quando as batatas estiverem moles, estão prontas',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Esprema as batatas com espremedor de batatas ou garfo enquanto elas ainda estiverem quentes (mais fácil)',
        'Esprema os alhos na panela com azeite',
        'Quando os alhos estiverem dourados, coloque as batatas',
        'Comece a mexer para não queimar',
        'Coloque o leite e continue a mexer até ficar uniforme',
        'Você pode colocar mais sal se precisar',
      ],
    },
  ],
};
