import React, { useReducer } from 'react';
import PeopleDrawer from 'Layout/components/PeopleDrawer';
import '_Shared/_base.scss';
import styles from 'Layout/layout.module.scss';
import Header from 'Layout/components/Header';

export const TabContext = React.createContext();
export const CHANGE_TAB_VALUE = 'changeTabValue';
const initialTabValue = 0;

const contextReducer = (_, { type, payload }) => {
  if(type === CHANGE_TAB_VALUE) {
    return payload;
  }
}

export default function Layout(props) {
  const [tabValue, dispatch] = useReducer(contextReducer, initialTabValue);

  return (
    <div className={styles.container}>
      <Header />

      <TabContext.Provider value={{ tabValue, dispatch }}>
        <div className={styles.content}>
          <PeopleDrawer />

          <main className={styles.main}>
            {props.children}
          </main>
        </div>
      </TabContext.Provider>
    </div>
  );
}
