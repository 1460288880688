// eslint-disable-next-line
export default {
  id: 'macarrao_ao_molho_de_camarao',
  type: 'savoury',
  title: 'Macarrão ao molho de camarão',
  ingredients: [
    'Alho e óleo a gosto',
    '1 porção de 100 g de camarão',
    '1 espinafre',
    'Cebola a gosto',
    'Sal a gosto',
    '1 pitada de pimenta-do-reino',
    '1 caixa de creme de leite',
    '1 pacote de macarrão fettuccine',
    '1 porção de bacon',
    '1 pacote de parmesão',
    'Salsinha a gosto',
  ],
  parts: [
    {
      title: 'Macarrão ao molho de camarão',
      methodOfPreparation: [
        'Coloque o óleo e alho na panela, e deixe fritar',
        'Adicione o camarão e deixe dourar até que mude de cor, e em seguida, reserve-o',
        'Na mesma panela, adicione o espinafre, a cebola, sal e a pimenta, refogando-os bem',
        'Adicione o creme de leite e deixe ferver',
        'Em seguida, adicione o macarrão, o camarão, a salsinha e o parmesão',
        'Misture tudo, espere formar um creme e desligue, servindo em seguida',
      ],
    },
  ],
};
